@import "../../Styles/Mixin.scss";
#login {
  overflow-x: hidden;
  .container {
    height: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    form {
      height: 100%;
      min-height: 100vh;
      width: 384px;
      min-width: 384px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .logo {
        width: 200px;
        height: 66px;
        min-width: 86px;
        min-height: 66px;
        background: url(/static/media/newlogo.1ddcb8b6.png);
        background-size: cover;
      }
      h1 {
        margin: 30px 0 0;
        color: #292929;
        font-size: 36px;
        line-height: 41px;
      }
      h2 {
        color: #acacac;
        font-size: 16px;
        line-height: 18px;
        margin: 12px 0 24px 0;
        font-weight: 400;
      }
      label.option {
        display: flex;
        align-items: center;
        width: 100%;
        border: 1px solid #acacac;
        box-sizing: border-box;
        margin-bottom: 20px;
        padding: 16px 22px;
        cursor: pointer;
        transition: all 0.2s;
        input {
          margin: 0 20px 0 0;
        }
        div {
          display: flex;
          flex-direction: column;
          b {
            font-size: 14px;
            line-height: 16px;
            color: #acacac;
            font-weight: 700;
            transition: all 0.2s;
          }
          span {
            font-size: 12px;
            line-height: 14px;
            color: #acacac;
            margin-top: 4px;
            transition: all 0.2s;
          }
        }
        &.active {
          background: rgba($colorPrimary, 0.08);
          border: 1px solid $colorPrimary;
          div {
            b {
              color: #292929;
            }
            span {
              color: #292929;
            }
          }
        }
      }
      .hr-green {
        width: 100%;
        height: 1px;
        background: #9dc44d;
        margin: 4px 0 24px 0;
      }
      label.input {
        display: flex;
        flex-direction: column;
        margin: 0 0 20px 0;
        width: 100%;
        span {
          font-size: 12px;
          line-height: 14px;
          color: #292929;
          font-weight: 400;
          margin: 0 0 10px 0;
        }
        input {
          box-sizing: border-box;
          border: 1px solid #292929;
          width: 100%;
          height: 56px;
          padding: 0px 22px;
          outline: none;
          &::placeholder {
            color: #d0d0d0;
          }
        }
      }
      button[type="submit"] {
        background: $colorPrimary;
        width: 100%;
        min-height: 56px;
        cursor: pointer;
        border: none;
        outline: none;
        font-size: 16px;
        font-weight: 700;
        color: #fff;
        &:hover {
          opacity: 0.9;
        }
      }
      .terms {
        font-size: 10px;
        line-height: 16px;
        color: #292929;
        margin: 24px 0 0 0;
        a {
          color: #ca0387;
        }
      }
      .signup {
        width: 100%;
        padding: 13px 0px;
        box-sizing: border-box;
        background: rgba(#9dc44d, 0.08);
        border: 1px solid #9dc44d;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px 0 0 0;
        b {
          font-size: 12px;
          line-height: 14px;
          font-weight: 500;
          color: #9dc44d;
        }
        span {
          font-size: 10px;
          line-height: 11px;
          font-weight: 400;
        }
      }
      .footer {
        font-size: 10px;
        line-height: 11px;
        color: #acacac;
        margin: 28px 0 0 0;
      }
    }
    .bg {
      width: 120%;
      height: 100%;
      position: absolute;
      left: 384px;
      margin-left: 8%;
      background: url(../../Images/bg-login.png);
      background-size: cover;
    }
  }
}
@media (max-width: 1500px) {
  #login {
    form {
      padding: 30px 0px;
    }
  }
}
@media (max-width: 400px) {
  #login {
    form {
      width: 100% !important;
      min-width: 100% !important;
    }
  }
}
