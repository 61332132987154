@import './Mixin.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
    margin: 0px;
    padding: 0px;
    font-family: 'Roboto', sans-serif;
}

.container {
    width: 1280px;
    max-width: 90%;
    margin: 0px auto;
}
h5 {
    text-transform: uppercase;
    font-weight: 400;
    color: rgba(#fff, .5);
}
h2 {
    color: rgba(#FFF, .9);
    font-weight: 300;
    b {
        font-weight: 500;
    }
}
.row {
    display: flex;
    justify-content: space-between;
}

.logo-default {
    .e-1 {
        animation: effect 8s infinite;
    }
    .e-2 {
        animation: effect 8s infinite;
        animation-delay: 1s;
    }
    .e-3 {
        animation: effect 8s infinite;
        animation-delay: 2s;
    }
    .e-4 {
        animation: effect 8s infinite;
        animation-delay: 3s;
    }
    .e-5 {
        animation: effect 8s infinite;
        animation-delay: 4s;
    }
    .e-6 {
        animation: effect 8s infinite;
        animation-delay: 5s;
    }
    .e-7 {
        animation: effect 8s infinite;
        animation-delay: 6s;
    }
    .e-8 {
        animation: effect 8s infinite;
        animation-delay: 7s;
    }
    @keyframes effect {
        0% {
            fill: white;
        }
        50% {
            fill: rgba(#FFF, .5);
        }
        100% {
            fill: white;
        }
    }
}

.table-default {
    width: 100%;
    text-align: left;
    border-radius: 5px;
    margin-bottom: 20px;
    background: #FFF;
    overflow: auto;
    box-shadow: 0px 10px 10px rgba(#000, .05);
    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 20px;
        box-sizing: border-box;
        > p {
            font-size: 16px;
            font-weight: 400;
            color: rgba(#000, .7);
        }
        form {
            input {
                border-radius: 100px;
                width: 200px;
                height: 35px;
                border: 1px solid rgba(#000, .05);
                font-size: 13px;
                padding: 0px 15px;
                box-sizing: border-box;
                outline: none;
                color: rgba(#000, .5);
            }
        }
    }
    table {
        width: 100%;
        border-spacing: 0px;
        th {
            text-transform: uppercase;
            font-weight: 500;
            font-size: 12px;
            padding: 14px 20px;
            color: rgba(#000, .5);
            background: rgba(#000, .03);
            border-bottom: 1px solid rgba(#000, .07);
            border-top: 1px solid rgba(#000, .07);
        }
        td {
            padding: 16px 20px;
            font-size: 13px;
            border-bottom: 1px solid rgba(#000, .05);
            color: rgba(#000, .6);
            white-space: nowrap;
        }
    }
    .paginator {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        ul {
            display: flex;
            justify-content: space-between;
            align-items: center;
            list-style: none;
            padding: 0px;
            margin: 0px;
            li {
                margin-right: 5px;
                button {
                    background: transparent;
                    border: 1px solid rgba(#000, .1);
                    border-radius: 100px;
                    width: 38px;
                    height: 38px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    box-sizing: border-box;
                    cursor: pointer;
                    font-size: 12px;
                    color: rgba(#000, .5);
                    transition: all .5s;
                    &:hover {
                        background: rgba(#000, .05);
                    }
                    &.normal {
    
                    }
                    &.active {
                        background: $colorPrimary;
                        color: #FFF;
                        border: 1px solid rgba($colorPrimary, 1);
                        box-shadow: 0px 10px 10px rgba(#000, .1);
                    }
                    &.inactive {
                        border: 1px solid rgba(#000, .1);
                        cursor:not-allowed
                    }
                }
            }
            p {
                font-size: 12px;
            }
        }
    }
}

.form-default {
    flex: 1;
    border-radius: 5px;
    background: #f7fafc;
    border: 1px solid rgba(0,0,0,.05);
    box-shadow: $boxShadow;
    display: flex;
    flex-direction: column;
    .head {
        width: 100%;
        background: #FFF;
        padding: 8px 20px;
        box-sizing: border-box;
        p {
            font-size: 15px;
            color: rgba(#000, .7);
            font-weight: 500;
        }
    }
    form {
        padding: 20px;
    }
}

.input-default {
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
    padding: 10px;
    span {
        font-size: 13px;
        padding-bottom: 5px;
        color: rgba(#000, .5);
    }
    input {
        min-height: 45px;
        height: 45px;
        color: #8898aa;
        background-color: #fff;
        padding: 0px 15px;
        font-size: 12px;
        color: #666;
        flex: 1;
        box-shadow: 0 1px 3px rgba(50, 50, 93, .1), 0 1px 0 rgba(0, 0, 0, .1);
        border: 0;
        border-radius: 4px;
        outline: none;
    }
}

.button-default {
    button {
        height: 45px;
        padding: 0px 20px;
        border-radius: 5px;
        color: #FFF;
        border: none;
        outline: none;
        box-shadow: 0px 5px 10px rgba(#000, .1);
        cursor: pointer;
        transition: all .2s;
        &:hover {
            box-shadow: 0px 5px 10px rgba(#000, .15);
            margin-top: -2px;
            margin-bottom: 2px;
        }
        svg {
            font-size: 18px;
        }
        &.info {
            background: $colorInfo;
        }
        &.success {
            background: $colorSuccess;
        }
        &.danger {
            background: $colorDanger;
        }
        &.warning {
            background: $colorWarning;
        }
    }
}