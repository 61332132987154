@import '../../Styles/Mixin.scss';

#home {
    
    h5 {
        text-transform: uppercase;
        font-weight: 400;
        color: rgba(#fff, 0.5);
    }
    .list-campgain {
        width: calc(100% + 60px);
        margin-left: -30px;
        height: 100px;
        display: flex;
        box-sizing: border-box;
        .skeleton {
            height: 100px;
            width: 300px;
            margin-right: 20px;
            display: flex;
        }
        .scroll-horizontal {
            @media (max-width: 400px) {
                overflow: auto !important;
            }
            > div {
                min-width: calc(100% + 1px);
            }
            padding: 0 0 0 30px;
            box-sizing: border-box;
        }
        .item {
            background: rgba(#fff, 0.7);
            margin-right: 20px;
            border-radius: 5px;
            padding: 20px;
            position: relative;
            display: flex;
            align-items: center;
            box-shadow: $boxShadow;
            cursor: pointer;
            transition: all 0.5s;
            &:last-child {
                margin-right: 60px;
            }
            &:hover {
                box-shadow: 0px 10px 10px rgba(#000, 0.1);
            }
            &.active {
                background: rgba(#fff, 1);
            }
            b {
                white-space: nowrap;
            }
            .icon {
                background: rgba($colorPrimary, 0.1);
                width: 50px;
                height: 50px;
                border-radius: 50px;
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    font-size: 20px;
                }
            }
            i {
                position: absolute;
                top: 0;
                right: 0;
                font-style: normal;
                margin: 10px;
                font-size: 11px;
            }
            b {
                color: rgba(#000, 0.6);
            }
        }
    }
    .list-questions {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
        padding-bottom: 10px;
        width: calc(100% + 20px);
        .question {
            width: calc(25% - 20px);
            @media (max-width: 1920px) {
                width: calc(33.3% - 20px);
            }
            @media (max-width: 1280px) {
                width: calc(50% - 20px);
            }
            @media (max-width: 780px) {
                width: calc(100% - 20px);
            }
            margin-bottom: 20px;
            margin-right: 20px;
            box-sizing: border-box;
            background: #fff;
            border: 1px solid rgba(#000, 0.1);
            box-shadow: $boxShadow;
            border-radius: 5px;
            padding: 20px;
            display: flex;
            flex-direction: column;
            transition: all 0.5s;
            animation: fadeIn 0.5s;
            position: relative;
            .header {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .icon {
                    min-width: 30px;
                    min-height: 30px;
                    border-radius: 4px;
                    background: rgba(#000, 0.05);
                    margin-right: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                p {
                    font-size: 13px;
                    color: #666;
                }
            }
            .chart {
                padding-bottom: 20px;
            }
            .total {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 40px 0px;
                height: 100%;
                span {
                    min-width: 100px;
                    min-height: 100px;
                    border-radius: 100%;
                    width: 200px;
                    height: 200px;
                    background-color: #2a59b2;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 28px;
                    border: 10px solid rgba(#fff, 0.3);
                    box-shadow: 0px 10px 100px rgba(#000, 0.5);
                    animation: zoomIn 0.5s;
                    color: #fff;
                }
            }
            .rating {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .legends {
                position: absolute;
                bottom: 20px;
                .legend {
                    display: flex;
                    align-items: center;
                    i {
                        margin-right: 4px;
                        width: 10px;
                        height: 10px;
                        border-radius: 100px;
                    }
                    span {
                        font-size: 13px;
                    }
                }
            }
        }
        .skeleton {
            width: calc(33.3% - 20px);
            height: 300px;
            margin-bottom: 20px;
            margin-right: 20px;
            span {
                height: 100%;
            }
        }
    }
}

#teste {
    width: 100px;
    height: 100px;
    font-weight: 100;
    path {
        fill: transparent;
        stroke: red;
        font-weight: 100;
        outline-style: solid;
        outline-width: 10px;
        outline-offset: -10px;
    }
}
