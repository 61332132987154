@import '../../Styles/Mixin.scss';

#profile {
    .forms {
        > div:nth-child(1) {
            margin-right: 30px;
            @media(max-width: 1150px) {
                margin-right: 0px;
                margin-bottom: 30px;
            }
        }
    }
    @media(max-width: 1150px) {
        .row {
            flex-direction: column;
        }
    }
}